<!-- =========================================================================================
  File Name: projects.vue
  Description: All Projects list
  ----------------------------------------------------------------------------------------
  Item Name: KOZO Admin - VueJS Dashboard 
  Author:  
  Author URL:  
========================================================================================== -->

<template>
  <div id="dashboard-analytics">
    <div class="vx-row">
      <div class="vx-col w-full text-right">
        <router-link to="/apps/projects">
          <feather-icon icon="XIcon" svgClasses="h-8 w-8" />
        </router-link>
      </div>
      <!-- DEPARTMENT FORMS -->
      <div class="vx-col w-full mb-base">
        <add-level></add-level>
      </div>

      <!-- DEPARTMENT LIST -->
      <div class="vx-col w-full mb-base">
        <remove-level></remove-level>
      </div>
    </div>
  </div>
</template>

<script>
import addLevel from "@/views/apps/projects/addLevel.vue";
import removeLevel from "@/views/apps/projects/removeLevel.vue";

export default {
  data() {
    return {
      isActive: false,
      analyticsData: analyticsData,
      isImp: false,
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 10,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  methods: {
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  },
  components: {
    addLevel,
    removeLevel
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
